import request from '@/utils/request'

// 查询所有公司
export function getAllCompany() {
  return request({
    url: '/company/get/all',
    method: 'get'
  })
}

// 根据id查询公司下所有系统,并查看公司选中哪些系统
export function getUcAllsystem(ucId) {
  return request({
    url: `/userSystemCompanyMap/get/${ucId}`,
    method: 'get'

  })
}

// 分页获取公司
export function searchCompany(searchForm) {
  return request({
    url: '/company/get/page',
    method: 'get',
    params: searchForm
  })
}

// 添加公司
export function addCompany(addForm) {
  return request({
    url: '/company/add',
    method: 'post',
    data: addForm
  })
}

// 根据公司Id获取公司数据
export function getIdCompany(ucId) {
  return request({
    url: `/company/get/${ucId}`,
    method: 'get'

  })
}

// 编辑公司
export function editCompany(editForm) {
  return request({
    url: '/company/update',
    method: 'put',
    data: editForm
  })
}

// 删除公司
export function deleteCompany(ucId) {
  return request({
    url: `/company/delete/${ucId}`,
    method: 'delete'

  })
}

// 查询树公司
export function getCompanyTree() {
  return request({
    url: '/company/get/tree',
    method: 'get'

  })
}

// 为公司赋予系统，多选系统  /userSystemCompanyMap/update
export function editUcSystem(data) {
  return request({
    url: '/userSystemCompanyMap/update',
    method: 'put',
    data: data
  })
}

// 为公司赋予系统，多选系统  /userSystemCompanyMap/update
export function getUserSystem() {
  return request({
    url: '/userSystemMap/get/userId',
    method: 'get'

  })
}

// 为公司赋予系统，多选系统  /userSystemCompanyMap/get/companySystem/{id}
export function getUcSystem(ucId) {
  return request({
    url: `/userSystemCompanyMap/get/companySystem/${ucId}`,
    method: 'get'

  })
}

